/** @jsxImportSource @emotion/react */
import { ReactNode, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import LoadingOverlay from 'react-loading-overlay-ts';
import { useTranslation } from 'react-i18next';
import { Autocomplete, Button, Card, CardActions, CardContent, ListSubheader, MenuItem, Paper, TextField, Typography, IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import List from '@mui/material/List';
import Badge from '@mui/material/Badge';

import { ViewHeader } from '@components/ViewHeader';
import { useAppTheme } from '@hooks/useAppTheme';
import { useGetProtocols } from '@hooks/useGetProtocols';
import { useGetFields } from '@hooks/useGetFields';
import { useGetTrialGrowerMembers } from '@hooks/useGetTrialGrowerMembers';
import { useGetTrialProducts } from '@hooks/useGetTrialProducts';
import { Trial, TrialGroupUser } from '@mytypes/trial';
import { GrowerSelectOption } from '@mytypes/grower';
import { USER_ROLE } from '@mytypes/user';
import { RootState } from '@state-mgmt/store';
import { setCurrentProtocol } from '@state-mgmt/slices/protocol-slice';
import { setTrialId, setUserRole } from '@state-mgmt/slices/appSlice';
import { TrialsGropedByYear, groupTrialsByYear } from '@utils/groupTrialsByYear';
import { formatPhoneNumber } from '@utils/formatPhoneNumber';

import { styles } from './styles';
import { useGetNearbyFields } from '@hooks/useGetNearbyFields';
import MapIcon from '@mui/icons-material/Map';

const Home = () => {
  const { t } = useTranslation();
  const theme = useAppTheme({});
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { nearbyFieldsRatioDistance, trialId, userRole, nearbyFieldsRatioUOM } = useSelector((state: RootState) => state.app);
  const { fields, nearbyFields, trialGrowers, trialOperatives, trials } = useSelector((state: RootState) => state.trial);
  const { currentProtocol, protocols } = useSelector((state: RootState) => state.protocol);

  const [selectedProtocol, setSelectedProtocol] = useState<string>(currentProtocol ? currentProtocol : 'all');
  const [selectedGrower, setSelectedGrower] = useState<GrowerSelectOption | undefined>();
  // const [selectedRole, setSelectedRole] = useState<string>(userRole);
  const [growers, setGrowers] = useState<TrialGroupUser[]>([]);
  const [expandFilters, setExpandFilters] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [getProtocolsData, getProtocols] = useGetProtocols();
  const [getFieldsData, getFields] = useGetFields();
  const [getMembersData, getMembers] = useGetTrialGrowerMembers();
  const [getTrialsProductsData, getTrialsProducts] = useGetTrialProducts();
  const getNearbyFields = useGetNearbyFields();

  const nearbyFieldsCount = useMemo(
    () =>
      nearbyFields.filter(
        ({ distanceMi, distanceKm }) =>
          (nearbyFieldsRatioUOM === 'miles' && distanceMi && distanceMi < nearbyFieldsRatioDistance) ||
          (nearbyFieldsRatioUOM === 'kilometers' && distanceKm && distanceKm < nearbyFieldsRatioDistance)
      ).length,

    [nearbyFields, nearbyFieldsRatioDistance, nearbyFieldsRatioUOM]
  );

  const trialOptions = useMemo(() => {
    const options = [] as ReactNode[];
    const trialsGroupedByYear = groupTrialsByYear(trials);

    const trialYears = Object.keys(trialsGroupedByYear)
      .sort((a: string, b: string) => a.localeCompare(b))
      .reverse();

    trialYears.forEach(year => {
      options.push(<ListSubheader key={year}>{year}</ListSubheader>);

      trialsGroupedByYear[year as keyof TrialsGropedByYear]
        .sort((a: Trial, b: Trial) => a.name.localeCompare(b.name))
        .forEach((trial: Trial) => {
          options.push(
            <MenuItem key={trial.id} value={trial.id}>
              {trial.name}
            </MenuItem>
          );
        });
    });
    return options;
  }, [trials]);

  const protocolsDropdownValues = useMemo(() => {
    if (protocols && protocols.length) {
      return protocols.map(p => ({ id: p.id, name: p.name })).sort((a: any, b: any) => a.name.localeCompare(b.name));
    }
    return [];
  }, [protocols]);

  useEffect(() => {
    if (getProtocolsData.isLoading || getFieldsData.isLoading || getMembersData.isLoading || getTrialsProductsData.isLoading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [getProtocolsData.isLoading, getFieldsData.isLoading, getMembersData.isLoading, getTrialsProductsData.isLoading]);

  useEffect(() => {
    setGrowers(
      (userRole === USER_ROLE.TRIAL_GROWER ? trialGrowers : trialOperatives)
        .filter(grower => {
          const conditionWithProtocol = userRole === USER_ROLE.TRIAL_OPERATIVE ? true : grower.protocol_ids?.length > 0;
          const conditionProtocolFilter = selectedProtocol !== 'all' ? grower.protocol_ids.some(({ protocol_id }) => protocol_id === selectedProtocol) : true;
          const conditionGrowerFilter = selectedGrower ? selectedGrower.id === grower.user_id : true;
          return conditionWithProtocol && conditionProtocolFilter && conditionGrowerFilter;
        })
        .sort((a: TrialGroupUser, b: TrialGroupUser) => a.first_name.localeCompare(b.first_name))
    );
  }, [trialGrowers, trialOperatives, selectedProtocol, selectedGrower, userRole]);

  const onTrialChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value as string;
    dispatch(setTrialId(value));
    localStorage.setItem('trialId', value);
    dispatch(setCurrentProtocol(undefined));
  };

  useEffect(() => {
    if (trialId) {
      getProtocols(trialId);
      getFields(trialId);
      getMembers(trialId);
      getTrialsProducts(trialId);
    }
  }, [trialId]);

  useEffect(() => {
    if (!isLoading) {
      getNearbyFields(fields, protocols);
    }
  }, [isLoading, fields, protocols, getNearbyFields]);

  const onProtocolChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value as string;
    setSelectedProtocol(value);
    dispatch(setCurrentProtocol(value === 'all' ? undefined : value));
    setSelectedGrower(undefined);
  };

  const onRoleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value as USER_ROLE;
    dispatch(setUserRole(value));
    // setSelectedRole(value);
    setSelectedProtocol('all');
    setSelectedGrower(undefined);
  };

  const onExpandFiltersBtnClicked = () => {
    setExpandFilters(!expandFilters);
  };

  const nearFieldsBtn = nearbyFields && (
    <IconButton color="primary" onClick={() => navigate('/trial-fields-map')}>
      {nearbyFieldsCount > 0 ? (
        <Badge badgeContent={nearbyFieldsCount} color="success">
          <MapIcon />
        </Badge>
      ) : (
        <MapIcon />
      )}
    </IconButton>
  );

  return (
    <Paper css={styles.container(theme)}>
      <ViewHeader title={t('home.title')} hideGoBackBtn={true} showRefreshBtn={true} rightBtn={nearFieldsBtn} />

      <div css={styles.scrollableArea(theme)}>
        <LoadingOverlay spinner active={isLoading} text={t('home.loading-message')} styles={{ wrapper: styles.listLoadingOverlayContent }}>
          {/* Trial Filter */}
          <TextField select fullWidth css={styles.filter(theme)} label={t('general.trial')} value={trialId} onChange={onTrialChange}>
            {trialOptions}
          </TextField>

          <div style={{ display: expandFilters ? 'block' : 'none' }}>
            {/* Protocol Filter */}
            <TextField
              select
              fullWidth
              label={t('general.protocol')}
              css={styles.filter(theme)}
              defaultValue="all"
              onChange={onProtocolChange}
              value={selectedProtocol}
              disabled={userRole === USER_ROLE.TRIAL_OPERATIVE}
            >
              <MenuItem key="all" value="all" defaultChecked>
                All
              </MenuItem>
              {protocolsDropdownValues.map(protocol => (
                <MenuItem key={protocol.id} value={protocol.id}>
                  {protocol.name}
                </MenuItem>
              ))}
            </TextField>

            {/* Role Filter */}
            <TextField
              select
              label={t('general.role')}
              fullWidth
              css={styles.filter(theme)}
              defaultValue={USER_ROLE.TRIAL_GROWER}
              onChange={onRoleChange}
              value={userRole}
              InputLabelProps={{ shrink: true }}
            >
              <MenuItem key={USER_ROLE.TRIAL_GROWER} value={USER_ROLE.TRIAL_GROWER} defaultChecked>
                {t('general.trial-grower-role')}
              </MenuItem>
              <MenuItem key={USER_ROLE.TRIAL_OPERATIVE} value={USER_ROLE.TRIAL_OPERATIVE}>
                {t('general.trial-operative-role')}
              </MenuItem>
            </TextField>

            {/* Grower Filter */}
            {userRole !== USER_ROLE.TRIAL_OPERATIVE && (
              <Autocomplete
                options={growers.map(({ user_id, first_name, last_name }) => ({ id: user_id, label: first_name + ' ' + last_name }))}
                renderInput={params => <TextField {...params} label={t('home.grower-filter-label')} />}
                renderOption={(props, option) => (
                  <li {...props} key={option.id}>
                    {option.label}
                  </li>
                )}
                onChange={(event: any, newValue) => setSelectedGrower(newValue ? newValue : undefined)}
                value={selectedGrower || null}
                css={styles.filter(theme)}
              />
            )}
          </div>

          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <IconButton aria-label="delete" onClick={onExpandFiltersBtnClicked}>
              {expandFilters ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </div>

          <List>
            {growers.map(({ email, first_name, last_name, phone, user_id }) => (
              <Card key={user_id} elevation={0} css={styles.card(theme)}>
                <Typography color="text.primary" css={styles.cardTitle(theme)}>
                  {first_name + ' ' + last_name}{' '}
                </Typography>

                <CardContent css={styles.cardContent(theme)}>
                  <Typography color="text.primary" fontSize={14}>
                    {t('general.email')}: {email}
                  </Typography>
                  <Typography color="text.primary" fontSize={14}>
                    {t('general.phone')}: {formatPhoneNumber(phone)}
                  </Typography>
                </CardContent>

                <CardActions css={styles.cardActions}>
                  <Button
                    size="small"
                    onClick={() => {
                      if (userRole !== USER_ROLE.TRIAL_OPERATIVE) {
                        navigate(`/grower/${user_id}`);
                      } else {
                        navigate(`/operative/${user_id}`);
                      }
                    }}
                    disabled={userRole !== USER_ROLE.TRIAL_OPERATIVE && !fields.filter(field => field.owner_id === user_id).length}
                    css={styles.cardActionsBtn}
                  >
                    {t('general.view')}
                  </Button>
                </CardActions>
              </Card>
            ))}
          </List>
        </LoadingOverlay>
      </div>
    </Paper>
  );
};

export default Home;
