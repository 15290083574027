import axios from 'axios';

import config from '../config';
import { AddAccessibleUserRequestPayload, AddAccessibleUserResponsePayload, Client, ClientUser } from '@mytypes/client';
import { validateToken } from '@utils/isTokenExpired';

export const getClientListApi = async (): Promise<{ clients: Client[]; favorite_client_id: string } | undefined> => {
  try {
    if (!(await validateToken())) {
      return;
    }

    const { data } = await axios({
      url: `${config.REACT_APP_CLIENT_SERVICE_URL}/v2/myself`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    });

    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getClientUsersListApi = async (clientId: string): Promise<ClientUser[] | undefined> => {
  try {
    if (!(await validateToken())) {
      return;
    }

    const { data } = await axios({
      url: `${config.REACT_APP_CLIENT_SERVICE_URL}/v2/accessible?client_id=${clientId}`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    });

    return data?.users;
  } catch (error) {
    console.log(error);
  }
};

export const addAccessibleUserApi = async (payload: AddAccessibleUserRequestPayload): Promise<AddAccessibleUserResponsePayload | undefined> => {
  try {
    if (!(await validateToken())) {
      return;
    }

    const { data } = await axios({
      url: `${config.REACT_APP_CLIENT_SERVICE_URL}/v2/accessible/add`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      data: {
        user_ids: payload.user_ids,
        client_id: payload.clientId
      }
    });

    return data as AddAccessibleUserResponsePayload;
  } catch (error) {
    console.log(error);
  }
};
