import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useAsyncCallback } from '@react-hooks-library/core';

import { Trial } from '@mytypes/trial';
import { getTrialsApi } from '@services/trial';
import { setTrials } from '@state-mgmt/slices/trial-slice';

export const useGetTrialsFields = () => {
  const dispatch = useDispatch();

  return useAsyncCallback(
    useCallback(async (clientId: string) => {
      try {
        if (!clientId) return;
        const { trials } = (await getTrialsApi(clientId)) || {};
        if (!trials) return;

        const activeTrials = trials.filter((trial: Trial) => trial.active);
        localStorage.setItem('trials', JSON.stringify(activeTrials));
        dispatch(setTrials(activeTrials));
        return activeTrials;
      } catch {
        const trials = JSON.parse(localStorage.getItem('trials') || '[]') as Trial[];
        dispatch(setTrials(trials));
        return trials;
      }
    }, [])
  );
};
