/** @jsxImportSource @emotion/react */
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Chip, Divider, FormControl, Paper, Step, StepConnector, StepLabel, Stepper, TextField, Typography } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import LoadingButton from '@mui/lab/LoadingButton';

import { ViewHeader } from '@components/ViewHeader';
import { WizardStepIcon } from '@components/WizardStepIcon';
import { GrowerEnrollmentForm, EnrolledGrower } from '@components/GrowerEnrollmentForm';
import { FieldEnrollmentForm, EnrolledField } from '@components/FieldEnrollmentForm';
import { ToastType } from '@components/ui/Toast';
import { useToast } from '@hooks/app/useToast';
import { useAppTheme } from '@hooks/useAppTheme';
import { checkUserRegisteredApi } from '@services/user';
import { RootState } from '@state-mgmt/store';
import { Trial } from '@mytypes/trial';
import { Protocol } from '@mytypes/protocol';
import { styles } from './styles';

let emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

const EnrollmentWizard = () => {
  const { t } = useTranslation();

  const ENROLL_WIZARD_STEPS = [t('enrollment-wizard.farmer-step'), t('enrollment-wizard.field-step')];
  const theme = useAppTheme({});
  const showToast = useToast();

  const navigate = useNavigate();
  const [step, setStep] = useState(0);
  const [searchParams] = useSearchParams();

  const { clientId, trialId } = useSelector((state: RootState) => state.app);
  const { trials } = useSelector((state: RootState) => state.trial);
  const { users } = useSelector((state: RootState) => state.client);

  // move three adove to check email form component
  const [isCheckingEmail, setIsCheckingEmail] = useState<boolean>(false);
  const [email, setEmail] = useState<string | undefined>();
  const [emailNotValid, setEmailNotValid] = useState<boolean>(false);
  const [emailStatus, setEmailStatus] = useState<string>('not-verified');
  const [userId, setUserId] = useState<string | undefined>();

  const [submitGrowerEnrollmentForm, setSubmitGrowerEnrollmentForm] = useState<boolean>(false);
  const [submitFieldEnrollment, setSubmitFieldEnrollment] = useState<boolean>(false);
  const [isEnrolling, setIsEnrolling] = useState<boolean>(false);
  const [enrolledGrower, setEnrolledGrower] = useState<EnrolledGrower | undefined>();
  const [enrolledField, setEnrolledField] = useState<EnrolledField | undefined>();
  const [userHasEnrolled, setUserHasEnrolled] = useState<boolean>(false); // mabe this one could be replace with enrolledGrower
  const [hasFieldSelected, setHasFieldSelected] = useState<boolean>(false);

  const trial: Trial | undefined = useMemo(() => trials.find(t => t.id === trialId), [trialId, trials]);

  useEffect(() => {
    const growerId = searchParams.get('grower_id');
    const field = searchParams.get('field');
    if (growerId && !field) {
      setUserId(growerId);
      const user = users.find(u => u.user_id === growerId);
      if (user) {
        setEmail(user.email);
        setEmailStatus('registered');
      }
    }
    if (growerId && field) {
      setUserId(growerId);
      setEmailStatus('registered');
      setUserHasEnrolled(true);

      const user = users.find(u => u.user_id === growerId);
      if (user) {
        setEnrolledGrower({
          growerId: user.user_id,
          fullName: '',
          protocolNames: [],
          trial,
          protocols: []
        });
      }

      setStep(1);
    }
  }, [searchParams, trial]);

  const checkEmail = () => {
    if (!clientId || !email) return;

    if (!email.match(emailRegex)) {
      setEmailNotValid(true);
      return;
    }
    setEmailNotValid(false);
    setIsCheckingEmail(true);
    checkUserRegisteredApi({ clientId, email })
      .then(data => {
        setUserId(data?.registered ? data.user_id : undefined);
        setEmailStatus(data?.registered ? 'registered' : 'not-registered');
        setIsCheckingEmail(false);
      })
      .catch(error => {
        console.log(error);
        setIsCheckingEmail(false);
      });
  };

  const enrollGrower = () => {
    if (!userHasEnrolled && !enrolledField) {
      setSubmitGrowerEnrollmentForm(true);
      return;
    }
    if (userHasEnrolled && hasFieldSelected) {
      setSubmitFieldEnrollment(true);
    }
    if (userHasEnrolled && !enrolledField) {
      setStep(1);
      return;
    }
    if (userHasEnrolled && enrolledField) {
      navigate('/splash?redirectTo=/enrollment');
    }
  };

  return (
    <Paper css={styles.container(theme)}>
      <ViewHeader
        title={step === 0 ? t('enrollment-wizard.grower-enrollment-title') : t('enrollment-wizard.field-enrollment-title')}
        onGoBackBtnPress={() => (userHasEnrolled ? navigate(`/splash?redirectTo=/enrollment`) : navigate(`/enrollment`))}
      />
      <Stepper alternativeLabel activeStep={step} connector={<StepConnector />} css={styles.stepper}>
        {ENROLL_WIZARD_STEPS.map(label => (
          <Step key={label}>
            <StepLabel StepIconComponent={WizardStepIcon}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div css={styles.scrollableArea(theme)}>
        <>
          {step === 0 && !userHasEnrolled && (
            <>
              <Typography color="text.primary">{t('enrollment-wizard.grower-personal-details')}</Typography>

              <div css={styles.chechEmail(theme)}>
                <FormControl fullWidth>
                  <TextField
                    disabled={!!searchParams.get('grower_id')}
                    label={t('general.email')}
                    autoComplete="off"
                    type={'email'}
                    value={email}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setEmail(event.target.value)}
                    error={emailNotValid}
                    helperText={emailNotValid ? t('enrollment-wizard.email-not-valid-message') : ''}
                    InputLabelProps={{ shrink: true }}
                  />
                </FormControl>

                <div css={styles.chechEmailButton(theme)}>
                  <LoadingButton
                    disabled={!email || !!searchParams.get('grower_id')}
                    variant="contained"
                    onClick={checkEmail}
                    loading={isCheckingEmail}
                    loadingIndicator={t('enrollment-wizard.grower-found-chip')}
                    css={styles.loadingButton}
                  >
                    {t('enrollment-wizard.check-email-button')}
                  </LoadingButton>
                  {emailStatus === 'registered' && (
                    <Chip icon={<CheckCircleOutlineIcon />} size="small" label={t('enrollment-wizard.grower-found-chip')} color="success" variant="outlined" />
                  )}
                </div>
              </div>
              <Divider css={styles.divider(theme)} />

              {emailStatus !== 'not-verified' && (
                <GrowerEnrollmentForm
                  trial={trial}
                  growerId={userId}
                  submit={submitGrowerEnrollmentForm}
                  isSubmiting={submiting => setIsEnrolling(submiting)}
                  handleSubmitComplete={enrolledUser => {
                    if (enrolledUser) {
                      setEnrolledGrower(enrolledUser);
                      setUserHasEnrolled(true);
                    }
                    setSubmitGrowerEnrollmentForm(false);
                  }}
                  email={email}
                  emailStatus={emailStatus}
                  onError={message => showToast({ type: ToastType.ERROR, children: t('enrollment-wizard.grower-not-found-message') })}
                />
              )}
            </>
          )}

          {step === 0 && userHasEnrolled && (
            <div css={styles.enrollmentSuccessfulMessage(theme)}>
              <Typography color="text.primary" css={styles.enrollmentSuccessfulMessageTitle(theme)}>
                {t('enrollment-wizard.enrollment-successful-message')}
              </Typography>
              <Typography color="text.primary">{enrolledGrower?.fullName}</Typography>
              <Typography color="text.primary">
                {t('general.trial')}: {enrolledGrower?.trial?.name}
              </Typography>
              <Typography color="text.primary">
                {t('general.protocol')}(s): {enrolledGrower?.protocolNames.join(', ')}
              </Typography>
            </div>
          )}

          {step === 1 && enrolledGrower && !enrolledField && (
            <FieldEnrollmentForm
              growerId={enrolledGrower.growerId}
              trial={enrolledGrower?.trial}
              protocol={enrolledGrower.protocols.at(0) as Protocol}
              submit={submitFieldEnrollment}
              isSubmiting={submiting => setIsEnrolling(submiting)}
              onSelectExistingField={() => setHasFieldSelected(true)}
              handleSubmitComplete={enrolledField => {
                if (enrolledField) {
                  setEnrolledField(enrolledField);
                  setStep(2);
                }
              }}
            />
          )}

          {step === 2 && enrolledField && (
            <div css={styles.enrollmentSuccessfulMessage(theme)}>
              <Typography color="text.primary" css={styles.enrollmentSuccessfulMessageTitle(theme)}>
                {t('enrollment-wizard.enrollment-successful-message')}
              </Typography>
              <Typography color="text.primary">
                {t('general.field')}: {enrolledField?.field?.fieldName}
              </Typography>
              <Typography color="text.primary">
                {t('general.trial')}: {enrolledField?.trial?.name}
              </Typography>
              <Typography color="text.primary">
                {t('general.protocol')}: {enrolledField?.protocol?.name}
              </Typography>
            </div>
          )}
        </>
      </div>

      {/* Actions Buttons */}
      <div css={styles.actionsContainer(theme)}>
        <Button css={styles.actionBtn(theme)} size="large" variant="outlined" onClick={() => navigate(`/splash?redirectTo=/enrollment`)} disabled={isEnrolling}>
          {step === 0 && userHasEnrolled ? t('general.done') : t('general.close')}
        </Button>

        <LoadingButton
          loading={isEnrolling}
          loadingIndicator={t('enrollment-wizard.enrolling-message')}
          css={styles.actionBtn(theme)}
          size="large"
          variant="contained"
          onClick={enrollGrower}
          disabled={emailStatus === 'not-verified' || (step === 1 && !hasFieldSelected)}
        >
          {step === 0 && userHasEnrolled ? t('enrollment-wizard.continue-button"') : step === 2 ? t('general.done') : t('general.next')}
        </LoadingButton>
      </div>
    </Paper>
  );
};

export default EnrollmentWizard;
