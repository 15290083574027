/** @jsxImportSource @emotion/react */

import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { changeLanguage } from 'i18next';
import { Paper } from '@mui/material';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

import logoDark from '@assets/images/logo-dark.png';
import logoLight from '@assets/images/logo-light.png';
import { useGetTrialsFields } from '@hooks/useGetTrialsFields';
import { useGetClients } from '@hooks/useGetClients';
import { useGetProtocols } from '@hooks/useGetProtocols';
import { useGetFields } from '@hooks/useGetFields';
import { useGetTrialGrowerMembers } from '@hooks/useGetTrialGrowerMembers';
import { useGetTrialProducts } from '@hooks/useGetTrialProducts';
import { Trial } from '@mytypes/trial';
import { Client } from '@mytypes/client';
import { RootState } from '@state-mgmt/store';
import {
  setIsSplashing,
  setNearbyFieldsRatioDistance,
  setNearbyFieldsRatioUOM,
  setOffSite,
  setShowAppNavigator,
  setSimulateGPS,
  setThemeMode,
  THEME_MODE
} from '@state-mgmt/slices/appSlice';
import { styles } from './styles';
import { setClientId, setTrialId } from '@state-mgmt/slices/appSlice';
import { useGetCrops } from '@hooks/useGetCrops';

const findLatestTrial = (trialsArray: Trial[]): Trial | null => {
  const years = Array.from(new Set(trialsArray.map(trial => trial.year)));
  years.sort((a, b) => b - a);
  const latestYear = years[0];
  const lastestYearTrials = trialsArray.filter(trial => trial.year === latestYear);
  lastestYearTrials.sort((a: Trial, b: Trial) => a.name.localeCompare(b.name));
  return lastestYearTrials.length > 0 ? lastestYearTrials[0] : null;
};

const Splash = () => {
  const [readyToRedirect, setReadyToRedirect] = useState(false);
  const [getClientsState, getClients] = useGetClients();
  const [getTrialsFieldsState, getTrialsFields] = useGetTrialsFields();
  const [getProtocolsState, getProtocols] = useGetProtocols();
  const [getCropsState, getCrops] = useGetCrops();

  const [getFieldsState, getFields] = useGetFields();
  const [getMembersState, getMembers] = useGetTrialGrowerMembers();
  const [getTrialsProductsState, getTrialsProducts] = useGetTrialProducts();
  const { clientId, trialId, themeMode } = useSelector((state: RootState) => state.app);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const caller = searchParams.get('caller');
    init(caller);
  }, []);

  const isLoading = useMemo(
    () =>
      getClientsState.isLoading &&
      getTrialsFieldsState.isLoading &&
      getProtocolsState.isLoading &&
      getCropsState.isLoading &&
      getFieldsState.isLoading &&
      getMembersState.isLoading &&
      getTrialsProductsState.isLoading,
    [
      getClientsState.isLoading,
      getTrialsFieldsState.isLoading,
      getProtocolsState.isLoading,
      getCropsState.isLoading,
      getFieldsState.isLoading,
      getMembersState.isLoading,
      getTrialsProductsState.isLoading
    ]
  );

  useEffect(() => {
    if (!isLoading && readyToRedirect) {
      dispatch(setShowAppNavigator(true));
      dispatch(setIsSplashing(false));
      const searchParams = new URLSearchParams(location.search);
      const redirectTo = decodeURIComponent(searchParams.get('redirectTo') || '');
      if (redirectTo && !redirectTo.includes('splash')) {
        navigate(redirectTo);
      } else {
        navigate('/');
      }
    }
  }, [isLoading, readyToRedirect]);

  const init = async (caller: string | null) => {
    let currentClientId = clientId;
    dispatch(setIsSplashing(true));
    dispatch(setShowAppNavigator(false));

    // init user settings
    if (!localStorage.getItem('themeMode')) {
      localStorage.setItem('themeMode', '0');
    } else {
      dispatch(setThemeMode(!!Number(localStorage.getItem('themeMode')) ? THEME_MODE.LIGHT : THEME_MODE.DARK));
    }
    if (!localStorage.getItem('simulateGPS')) {
      localStorage.setItem('simulateGPS', '0');
    } else {
      dispatch(setSimulateGPS(!!Number(localStorage.getItem('simulateGPS'))));
    }
    if (!localStorage.getItem('offsite')) {
      localStorage.setItem('offsite', '0');
    } else {
      dispatch(setOffSite(!!Number(localStorage.getItem('offsite'))));
    }
    if (!localStorage.getItem('nearbyFieldsRatioDistance')) {
      localStorage.setItem('nearbyFieldsRatioDistance', '25');
    } else {
      dispatch(setNearbyFieldsRatioDistance(Number(localStorage.getItem('nearbyFieldsRatioDistance'))));
    }

    if (!localStorage.getItem('nearbyFieldsRatioUOM')) {
      localStorage.setItem('nearbyFieldsRatioUOM', 'miles');
    } else {
      dispatch(setNearbyFieldsRatioUOM(localStorage.getItem('nearbyFieldsRatioUOM') || 'miles'));
    }

    const language = localStorage.getItem('language');
    if (!language) {
      localStorage.setItem('language', 'en');
    } else {
      changeLanguage(language);
    }

    const { clients, favoriteClientId } = await getClients();

    if (!clients.length) {
      setReadyToRedirect(true);
      return;
    }

    if (caller === 'signin') {
      const clientsSorted = [...clients].sort((a: Client, b: Client) => a.name.localeCompare(b.name));
      const defaultClientId = favoriteClientId || clientsSorted.at(0)?.id;
      if (defaultClientId) {
        dispatch(setClientId(defaultClientId));
        localStorage.setItem('clientId', defaultClientId);
        currentClientId = defaultClientId;
      }
    } else {
      const localClientId = localStorage.getItem('clientId');
      if (!clientId && localClientId) {
        dispatch(setClientId(localClientId));
        localStorage.setItem('clientId', localClientId);
        currentClientId = localClientId;
      }
    }

    if (!currentClientId) {
      setReadyToRedirect(true);
      return;
    }

    const trials = await getTrialsFields(currentClientId);
    if (trials?.length) {
      const defaultTrialId = findLatestTrial(trials)?.id || trials[trials.length - 1].id;
      const localTrialId = localStorage.getItem('trialId');
      const activeTrial = trialId ? trialId : localTrialId || defaultTrialId;
      localStorage.setItem('trialId', activeTrial);

      dispatch(setTrialId(activeTrial));

      getProtocols(activeTrial);
      getCrops();
      getFields(activeTrial);
      getMembers(activeTrial);
      getTrialsProducts(activeTrial);
    }

    // only for Safari, ask permission for notification after a user gesture.
    // Notification.requestPermission().then(permission => fetchToken());
    setTimeout(() => setReadyToRedirect(true), 2000);
  };

  return (
    <Paper elevation={2} css={styles.container}>
      <img src={themeMode === THEME_MODE.DARK ? logoDark : logoLight} alt="logo" css={styles.image} />

      <Box css={styles.progress}>
        <LinearProgress />
      </Box>
    </Paper>
  );
};

export default Splash;
